<template>
  <div id="app" :class="{ disabledPointerEvents: !pointerEvents }">
    <Sidebar
      ref="sidebar"
      v-if="this.showSidebar"
      @changeToSection="changeToSection"
    ></Sidebar>

    <transition name="fade" mode="out-in">
      <router-view />
    </transition>

    <transition name="fade" mode="out-in">
      <Info
        v-if="showInfo"
        :section="infoSection"
        @closeInfo="closeInfo"
      ></Info>
      <Rewards v-if="showRewards" @closeRewards="closeRewards"></Rewards>
    </transition>

    <top-menu
      v-if="showTopOptions"
      :map="showMapButton"
      :scanner="showScannerButton"
      :help="showHelpButton"
      @clicked="selectedMenuOption"
    ></top-menu>

    <ProgressBar
      v-if="showProgressBar"
    ></ProgressBar>

    <downloading v-if="swStatus === 'downloading'"></downloading>
    <splash />

    <div id="girar">
      <div class="contGirar">
        <figure class="imgGirar">
          <img src="img/giro.png" alt="">
        </figure>
        <div class="txtGiro">{{ $t("girar.text") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Info from "@/components/Info.vue";
import Rewards from "@/components/Rewards.vue";
import Sidebar from "@/components/Sidebar.vue";
import TopMenu from "@/components/TopMenu.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import Splash from "@/components/Splash.vue";
import Downloading from "@/components/Downloading.vue";
import { Analytic } from "./../public/js/analytics/Analytic.js";
import platform from "platform";

var analytic = new Analytic({
  url: "./setAnalytic.php",
  idPag: 1,
  namePag: "Main",
});

export default {
  name: "App",
  data() {
    return {
      pointerEvents: true, // enable or disable interaction on app

      showTopOptions: true, // show top options
      showProgressBar: true, // show rewards progress bar
      showMapButton: true, // show map button on top options
      showScannerButton: true, // show scanner button on top options
      showHelpButton: true, // show help button on top options
      showSidebar: true, // show sidebar

      infoSection: null, // selected section to show in info
      showInfo: false, // show info section
      showRewards: false, // show rewards section
      swStatus: null, // status of the download service worker
    };
  },
  created() {
    // using device language or defaults to "en"
    if (this.$store.state.locale == null) {
      let deviceLanguage = this.getDeviceLanguage();
      this.$store.commit("updateLocale", deviceLanguage);
      analytic.registerEvent("URL", window.location.href);
      setTimeout(() => {
        try {
          var obj = {
            browser: platform.name,
            browserVersion: platform.version,
            OSFamily: platform.os.family,
            OSVersion: platform.os.version,
            model: platform.product,
          };
          var data = JSON.stringify(obj);
          analytic.registerEvent("BrowserInfo", data);
        } catch (e) {
          console.log("error: ", e);
        }
      }, 100);
      setTimeout(() => {
        analytic.registerEvent("Lang", deviceLanguage);
      }, 150);
      setTimeout(() => {
        if (document.referrer != "") {
          analytic.registerEvent("Referrer", document.referrer);
        }
      }, 200);
    } else {
      analytic.registerEvent("Lang", this.$store.state.locale);
    }

    document.addEventListener("visibilitychange", this.visibilityChange, false);
    navigator.serviceWorker.ready.then(this.swReady);

    // register listener for toggle pointer events
    this.$root.$on("togglePointerEvents", this.togglePointerEvents);
  },
  mounted() {
    let route = this.$route.name;
    let query = this.$route.query;
    this.configSidebar(route, query);
    this.configTopOptions(route, query);
    this.configProgressBar(route, query);
    this.$nextTick(() => {
      this.localizeParkData();
    });

    window.addEventListener("swStatus-changed", (event) => {
      this.swStatus = event.detail.storage;
      console.log("[sw chgd] ", this.swStatus);
    });
    navigator.serviceWorker.addEventListener("message", function (event) {
      console.log("[sw msg] ", event);
    });
  },
  components: {
    TopMenu,
    ProgressBar,
    Sidebar,
    Splash,
    Downloading,
    Info,
    Rewards,
  },
  watch: {
    // Every time the routes changes, config sidebar and top options
    $route(to) {
      this.configSidebar(to.name, to.query);
      this.configTopOptions(to.name, to.query);
      this.configProgressBar(to.name, to.query);

      if (this.$store.state.isSidebarNeeded) {
        // comes from only detail
        this.$store.commit("setSidebarNeeded", false);
        this.openSidebar();
      }
    },
  },
  methods: {
    // Toggle the pointers events on entire app based on status
    //  params:
    //     - status: Bool
    togglePointerEvents(status) {
      this.pointerEvents = status;
    },
    // Register visibility change on analytics
    //  params:
    //     - e: visibilty change param (?)
    visibilityChange(e) {
      e.value;
      analytic.registerEvent("Hide/minimized", document.hidden);
      if (location.pathname+location.search == "/map?type=route") {
        this.$router.push("NextRoute");
      }
      if(location.pathname.toLowerCase() == "/scanner"){
        this.$router.go(-1);
      }
    },
    // Selected menu option
    //  params:
    //     - value: the id of the button pressed (String)
    selectedMenuOption(value) {
      switch (value) {
        case "1":
          switch (this.$store.state.locale) {
            case "es":
              this.$i18n.locale = "fr";
              this.$store.commit("updateLocale", "fr");
              this.$store.commit("setParkData", this.$store.state.dataFr);
              break;
            case "en":
              this.$i18n.locale = "es";
              this.$store.commit("updateLocale", "es");
              this.$store.commit("setParkData", this.$store.state.dataEs);
              break;
            case "pt":
              this.$i18n.locale = "en";
              this.$store.commit("updateLocale", "en");
              this.$store.commit("setParkData", this.$store.state.dataEn);
              break;
            case "fr":
              this.$i18n.locale = "pt";
              this.$store.commit("updateLocale", "pt");
              this.$store.commit("setParkData", this.$store.state.dataPt);
              break;
            default:
              break;
          }

          analytic.registerEvent("Lang", this.$i18n.locale);
          break;
        case "2":
          // map
          // analytic.registerEvent("Mapa", "open");
          this.pushRouteTo("Map", { type: "spots" });
          break;
        case "3":
          // qr
          analytic.registerEvent("Scanner", "open");
          this.pushRouteTo("scanner");
          break;
        case "4":
          // qr
          analytic.registerEvent("Help", "open");
          this.pushRouteTo("help");
          break;
        default:
          break;
      }
    },
    // Router function helper to push a route with or without params
    //  params:
    //     - route: name of the route (String)
    //     - params: params if any (Object)
    pushRouteTo(route, params) {
      if (params) {
        this.$router.push({ name: route, query: params }).catch(() => {});
      } else {
        this.$router.push(route, params).catch(() => {});
      }
    },
    // Config top options depending on current route
    //  params:
    //     - routeName: name of the route (String)
    //     - routeQuery: query of the route if any (Object)
    configTopOptions(routeName, routeQuery) {
      switch (routeName) {
        case "Home":
          this.showMapButton = false;
          this.showScannerButton = false;
          this.showHelpButton = false;
          this.showTopOptions = false;
          break;
        case "Map":
        case "Scanner":
        case "Rating":
        case "OnBoarding":
        case "Rewards":
        case "Help":
          this.showTopOptions = false;
          break;

        case "Route":
          this.showMapButton = true;
          this.showScannerButton = true;
          this.showHelpButton = true;
          this.showTopOptions = Object.keys(routeQuery).length == 0;
          break;

        default:
          this.showMapButton = true;
          this.showScannerButton = true;
          this.showHelpButton = true;
          this.showTopOptions = true;
          break;
      }
    },
    // Config progress bar depending on current route
    //  params:
    //     - routeName: name of the route (String)
    //     - routeQuery: query of the route if any (Object)
    configProgressBar(routeName, routeQuery) {
      switch (routeName) {
        case "Home":
        case "Map":
        case "Scanner":
        case "Rating":
        case "OnBoarding":
        case "Rewards":
        case "Help":
          this.showProgressBar = false;
          break;

        case "Route":
          this.showProgressBar = Object.keys(routeQuery).length == 0;
          break;

        default:
          this.showProgressBar = true;
          break;
      }
    },
    // Config SideBar menu depending on current route
    //  params:
    //     - routeName: name of the route (String)
    //     - routeQuery: query of the route if any (Object)
    configSidebar(routeName, routeQuery) {
      switch (routeName) {
        case "Map":
        case "Scanner":
        case "Rating":
        case "OnBoarding":
        case "Rewards":
        case "Help":
        case "Discover":
          this.showSidebar = false;
          break;
        case "Home":
          this.showSidebar = true;
          break;
        case "Route":
          // if route has query params, disable the sidebar
          this.showSidebar = Object.keys(routeQuery).length == 0;
          break;
        default:
          this.showSidebar = true;
      }
    },
    // Localize park data depending on the locale saved on store
    localizeParkData() {
      switch (this.$store.state.locale) {
        case "es":
          this.$store.commit("setParkData", this.$store.state.dataEs);
          break;
        case "en":
          this.$store.commit("setParkData", this.$store.state.dataEn);
          break;
        case "pt":
          this.$store.commit("setParkData", this.$store.state.dataPt);
          break;
        case "fr":
          this.$store.commit("setParkData", this.$store.state.dataFr);
          break;
        default:
          break;
      }
    },
    // Sidebar section selection
    //  params:
    //     - sec: name of the section (String)
    changeToSection(sec) {
      switch (sec) {
        case "introduction":
        case "help":
        case "warnings":
        case "about":
        case "discover":
          this.infoSection = sec;
          this.showInfo = true;
          break;
        case "rewards":
          this.showRewards = true;
          break;

        default:
          break;
      }
    },
    openSidebar() {
      this.$nextTick(() => {
        if (this.$refs.sidebar) {
          this.$refs.sidebar.forceSidebar(false);
        } else {
          console.error("failed the sidebar");
        }
      });
    },
    // Close the modally presented info section
    closeInfo() {
      this.infoSection = null;
      this.showInfo = false;
    },
    // Close the modally presented rewards section
    closeRewards() {
      this.showRewards = false;
    },
    // Obtain broswer/device language, if the found locale ain't valid or is null, default to portuguese
    getDeviceLanguage() {
      var locale = navigator.language || navigator.userLanguage;
      locale = locale.toLocaleLowerCase().substring(0, 2);

      if (this.$store.state.validLocales.includes(locale)) {
        return locale;
      } else {
        return "en";
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  text-align: center;
  color: var(--app-black);
  position: absolute;
  width: 100%;
  height: 100%;

  &.disabledPointerEvents {
    pointer-events: none;
  }
}

/**Animatons */
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
