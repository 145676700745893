<template>
  <div class="quiz">
    <div v-if="question" class="question-wrapper">
      <p v-html="question"></p>
    </div>
    <div v-if="answers" class="answers-wrapper">
      <big-button
        ref="answer"
        v-for="ans in answers"
        v-bind:key="ans.id"
        :data-opt="ans.id"
        :onClick="chosenAnswer"
        type="answer"
        :allCaps="false"
        :selectable="true"
        :class="{ selected: ans.id === selected }"
        >{{ ans.answer }}
      </big-button>
    </div>
  </div>
</template>

<script>
import BigButton from "@/components/BigButton.vue";
import { Analytic } from "../../public/js/analytics/Analytic.js";
import { Howl } from "howler";
var options = {
  url: "./setAnalytic.php",
  idPag: 1,
  namePag: "Quiz",
};
var analytic = new Analytic(options);
export default {
  name: "Quiz",
  components: {
    BigButton,
  },
  props: {
    challengeId: {
      type: Number,
      required: true,
    },
    question: {
      type: String,
      required: true,
    },
    answers: {
      type: Array,
      required: true,
    },
    onFinished: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      selected: null,
      success: null,
      fail: null,
    };
  },
  mounted() {
    analytic.registerEvent("Quiz", "open");
    // let route = this.$store.state.currentRoute;

    this.fail = new Howl({
      src: ["audios/desafio_incorrecto.mp3"],
      loop: false,
      volume: 0.7
    });
    this.success = new Howl({
      src: ["audios/desafio_correcto.mp3"],
      loop: false,
      volume: 0.7
    });
  },
  computed: {
    challengeAudio() {
      return this.challenge.audio;
    },
    challengeFinishedAudio() {
      return this.challenge.finishedAudio;
    },
  },
  methods: {
    shuffle(a) {
      for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
      }
      return a;
    },
    chosenAnswer(evt) {
      this.$root.$emit("togglePointerEvents", false);
      let chosen = this.answers.find((ans) => ans.id == evt.target.dataset.opt);
      this.selected = chosen.id;

      let saveObj = {
        id: this.$store.state.currentRoute.prize,
        challenge: this.challengeId,
        correct: chosen.isCorrect,
      };

      if(chosen.isCorrect){
        this.success.play();
      }else{
        this.fail.play();
      }

      
        if (!this.containsObject(saveObj, this.$store.state.finishedChallenges)) {
          /*if (this.challengeId == 1) {
            this.$store.commit("registerFinishedChallenge", saveObj);
          }*/
          this.$store.commit("registerFinishedChallenge", saveObj);
        }
        setTimeout(() => {
          var routeId = this.$store.state.currentRoute.id;
          if (routeId == "entrada") {
            routeId = "entrada";
          }
          analytic.registerEvent(
            `Quiz ${routeId}`,
            ` result: ${chosen.isCorrect}`
          );
          this.$root.$emit("togglePointerEvents", true);
          this.onFinished(chosen.isCorrect);
        }, 1500);
        var todasRutas = this.$store.state.finishedRoutes;
        if((todasRutas.includes("entrada") == false)){
          this.$store.state.finishedRoutes.push("entrada");
            let saveObjE = {
              id: "cam_1",
              challenge: 1,
              correct: true,
            };

            this.$store.commit("registerFinishedChallenge", saveObjE);
          
          this.$store.commit("entradaState", true);
        }
    },
    containsObject(obj, list) {
      return list.find((item) => item.id === obj.id) !== undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
.quiz {
  position: relative;
  display: flex;
  flex: 1;
  flex-flow: column;
  align-items: center;
  overflow: auto;

  .question-wrapper {
    margin: 0 0 1rem 0;
    padding: 1rem 0 0.5rem 0;
    //overflow: auto;
    mask-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 4%,
      rgba(0, 0, 0, 1) 96%,
      rgba(0, 0, 0, 0) 100%
    );
    -webkit-mask-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 4%,
      rgba(0, 0, 0, 1) 96%,
      rgba(0, 0, 0, 0) 100%
    );

    p {
      font-family: "Lato";
      font-size: 1.4rem;
      line-height: 1.8rem;
      font-weight: 700;
      margin: 0;
      color: #5c5c5c;
      text-align: center;
    }
  }

  .answers-wrapper {
    display: flex;
    width: 100%;
    flex-flow: column;
    align-items: center;
    justify-content: space-evenly;
    flex: 0.8;
    .big-button {
      padding: 1.2rem;
    }
  }
}
</style>
