<template>
    <div class="Condessa" id="Condessa">
        <div class="videoContainer" ref="video">
            <video ref="videos" class="video-background" autoplay muted playsinline></video>

            <canvas ref="canvas" id="canvas" :width="360" :height="480"></canvas>

            <div id="draggable" class="ui-widget-content draggable">
                <img :src="require('@/assets/condesa_ra_3.png')" alt="">
            </div>
        </div>

        <div class='capturePhoto' v-on:click="capturePhoto()"><img :src="require('@/assets/btn_foto.png')" alt=""></div>

        <button class="round-btn close" @click="closeModalCondessa">
            <img
                src="img/02_generales/cerrar.png"
                alt="close button"
            />
        </button>

        <div class="camera-download downloadDisable">
            <a id="downloadPhoto" download="condessa-selfie.png" class="button" role="button" @click="downloadImage" :href="`${output}`"><img :src="require('@/assets/btn_desc.png')" alt=""></a>
        </div>

        <div class='girarCamara' v-on:click="girarCamara()"><img :src="require('@/assets/btn_girar.png')" alt=""></div>

        <AppAlert ref="appAlert" />
    </div>
</template>
  
<script>
import { Analytic } from "./../../public/js/analytics/Analytic.js";
import AppAlert from "@/components/AppAlert.vue";
//import { Howl } from 'howler';

const $ = require('jquery');
var Draggabilly = require('draggabilly');


var analytic = new Analytic({
    url: "./setAnalytic.php",
    idPag: 1,
    namePag: "Condessa",
});

export default {
    name: "Condessa",
    data() {
        return {
            capture: null,
            typeCamera: null,
            canvas: null,
            output: null,
            ok: false,
            isCanvasShow: false,
            timeout: false,
            title2: this.$t("ARVisorMarker.title2"),
            angle: 0,
            isIOS: false,
            indicator: null,
            pointDegree: null,
            compass: null,
            location: null,
            isUserNearEnough: false,
            currentRoute: null,
            outsideCoordinates: [],
            videoConstraints: {
                video: {
                    facingMode: {
                        exact: "environment",
                    },
                },
            },
        };
    },
    mounted() {
        this.currentRoute = this.$store.state.parkData.canalArea;
        this.$store.commit("arShow", true);
        analytic.registerEvent("Condessa", "open");
        if (this.needsOnboarding()) {
            this.$store.commit("setCondessaOnboardingAsShown");
            this.$refs.appAlert.showAlert({
                type: "message",
                msg: this.$t("selfie.instruction"),
            });
        }
        /*this.sound = new Howl({
            src: ['audios/baroque_ra7.mp3'],
            html5: true,
            loop: true,
            volume: 0.5
        })
        this.sound.play();*/

        this.loadCameraBackground();
        this.isIOS = this.checkForiOS();

        this.initDrag();
    },
    components: {
        AppAlert,
    },
    methods: {
        closeModalCondessa() {
            analytic.registerEvent("Condessa", "close");
            this.$parent.closeCondessa();
        },
        initDrag() {
            var title2 = this.title2;
            var draggableElems = document.querySelectorAll('.draggable');
            var draggies = []
            for (var i = 0; i < draggableElems.length; i++) {
                var draggableElem = draggableElems[i];
                var draggie = new Draggabilly(draggableElem, {});
                draggie.on('dragStart', function () {
                    $(".mano").fadeOut();
                    setTimeout(() => {
                        $(".title-container").html(title2);
                        $(".title-wrap").removeClass("title-ok");

                        setTimeout(() => {
                            this.ok = true;
                            console.log(this.ok);
                            //$(".ok").removeClass('ok-disable');
                        }, 1050);
                    }, 2000);
                });
                draggies.push(draggie);
            }
        },
        checkForiOS() {
            return (
                [
                    "iPad Simulator",
                    "iPhone Simulator",
                    "iPod Simulator",
                    "iPad",
                    "iPhone",
                    "iPod",
                ].includes(navigator.platform) ||
                (navigator.userAgent.includes("Mac") && "ontouchend" in document)
            );
        },
        loadCameraBackground() {
            if (
                !("mediaDevices" in navigator) ||
                !("getUserMedia" in navigator.mediaDevices)
            ) {
                return;
            }

            const video = document.querySelector(".video-background");
            if ("mediaDevices" in navigator && navigator.mediaDevices.getUserMedia) {
                let stream;
                this.capture = async facingMode => {
                    const options = {
                        audio: false,
                        video: {
                        facingMode,
                        },
                    };
                    try {
                        if (stream) {
                            const tracks = stream.getTracks();
                            tracks.forEach(track => track.stop());
                        }
                        stream = await navigator.mediaDevices.getUserMedia(options);
                    } catch (e) {
                        alert(e);
                        return;
                    }
                    video.srcObject = null;
                    video.srcObject = stream;
                    video.play();
                }
                this.capture("user");
                this.typeCamera = 'user';
            }
        },
        capturePhoto: function () {
            //analytic.registerEvent("AR Visor Marker", "closed");
            /*if (this.sound != null) {
                this.sound.stop();
                this.sound.unload();
                this.sound = null;
            }*/
            //this.$router.push("complete");
            if(this.isCanvasShow){
                $(".camera-download").addClass("downloadDisable");

                const context = this.$refs.canvas.getContext("2d");
                context.clearRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height);
                this.isCanvasShow = false;
            }else{
                $(".camera-download").removeClass("downloadDisable");

                var context = this.$refs.canvas.getContext("2d");
                context.drawImage(this.$refs.videos, 0, 0, 360, 480);

                const getScreenshotOfElement = async (el) => {
                    const options = {
                        type: 'dataURL'
                    }
                    this.output = await this.$html2canvas(el, options);
                }

                const div = this.$refs.video;
                getScreenshotOfElement(div)

                this.isCanvasShow = true;
            }

            console.log("click");
        },
        needsOnboarding() {
            let onBoardingAlreadyShown = this.$store.state.condessaOnboardingShown;
            return !onBoardingAlreadyShown;
        },
        girarCamara(){
            if(this.typeCamera == 'user'){
                this.capture('environment');
                this.typeCamera = 'environment';
            }else{
                this.capture('user');
                this.typeCamera = 'user';
            }

            $(".camera-download").addClass("downloadDisable");

            const context = this.$refs.canvas.getContext("2d");
            context.clearRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height);
        },
        downloadImage(){
            /*const download = document.getElementById("downloadPhoto");
            const canvas = document.getElementById("canvas").toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
            download.setAttribute("href", canvas);*/
            analytic.registerEvent("Condessa selfie", "Download");
            console.log("Descarga");
        }
    },
    /*beforeDestroy() {
        //this.video = document.getElementById('arjs-video');
        //document.body.removeChild(this.video);
        //location.reload();
        if (this.sound != null) {
            this.sound.stop();
            this.sound.unload();
            this.sound = null;
        }
    }*/
};
</script>
  
<style lang="scss">
.Condessa {
    position: fixed;
    background-color: black;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1001;

    .bg-layer .alert-container.onlyMessage span{
        text-align: left;
    }

    .close {
        z-index: 401;
        left: unset;
        right: 1rem;
    }

    .videoContainer{
        width: 360px;
        height: 480px;
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 5%;

        canvas{
            width: 360px;
            height: 480px;
            position: absolute;
            top: 0;
            left: 0;
        }

        #draggable {
            width: 250px;
            height: 327px;
            padding: 0.5em;
            z-index: 1;

            img {
                width: 250px;
                height: 327px;
                object-fit: contain;
                object-position: center;
            }
        }

        .video-background {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 360px;
            height: 480px;
            object-fit: cover;
        }
    }

    .capturePhoto, .girarCamara, .camera-download {
        position: absolute;
        bottom: 5%;
        width: 3rem;
        height: 3rem;
        z-index: 401;

        img{
            object-fit: contain;
            object-position: center;
            width: 100%;
            height: 100%;
        }
    }

    .capturePhoto{
        margin: 0 auto;
        width: 4rem;
        height: 4rem;
        bottom: 4%;
    }

    .camera-download{
        left: 10%;
    }

    .girarCamara{
        right: 10%;
    }

    .downloadDisable{
        pointer-events: none;
        opacity: 0.5;
    }

    .close {
        left: unset;
        right: 1rem;
    }

    a-scene {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
    }

    .title-ok{
        bottom: -0.5rem;
    }

    .title-wrap{
        display: flex;
        flex-flow: column;
        position: absolute;
        bottom: 5%;
        width: 100%;

        .title-container {
            position: relative;
            left: 0;
            right: 0;
            //bottom: 7%;
            margin: auto;
            color: #F8F7F3;
            font-size: 1.5rem;
            font-family: "Lato";
            width: 86%;
            display: flex;
            flex-flow: column;
        }

        .ok-disable{
            pointer-events: none;
            opacity: 0;
        }
    }

    .title-ok{
        bottom: -1rem;
    }

    .disclaimer-container {
        position: absolute;
        color: white;
        background-color: var(--app-modalBg);
        border-radius: 0.5rem;
        width: 95%;
        flex: auto;
        bottom: 1vh;
        left: 0;
        right: 0;
        text-align: left;
        margin: auto;
        padding: 0.5rem 1rem;
        font-family: "Lato";
        font-size: 0.8rem;
        z-index: 1;
    }

    .onBoarding {
        z-index: 12;
    }
}

@keyframes opacidad {
    0%{
        opacity: 1;
        transform: scale(1.3);
        -webkit-transform: scale(1.3);
    }
    50%{
        opacity: 0.5;
        transform: scale(1);
        -webkit-transform: scale(1);
    }
    100%{
        opacity: 1;
        transform: scale(1.3);
        -webkit-transform: scale(1.3);
    }
}

@-webkit-keyframes opacidad {
    0%{
        opacity: 1;
        transform: scale(1.3);
        -webkit-transform: scale(1.3);
    }
    50%{
        opacity: 0.5;
        transform: scale(1);
        -webkit-transform: scale(1);
    }
    100%{
        opacity: 1;
        transform: scale(1.3);
        -webkit-transform: scale(1.3);
    }
}
</style>