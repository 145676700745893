<template>
  <transition name="fade">
    <div class="bg-layer" :class="{ opaque: this.bgLayer }" v-if="shown">
      <div
        class="alert-container"
        :class="{ onlyMessage: this.needsCloseButton }"
      >
        <!-- slot for message -->
        <span v-html="message">
          <!--{{ message }}-->
        </span>

        <!-- multiple confirm buttons -->
        <div
          class="options-container multipleButtons"
          v-if="needsConfirmButtons"
        >
          <button
            class="option-btn"
            data-action="cancel"
            @click="triggerAction"
          >
            CANCEL</button
          ><button
            class="option-btn"
            data-action="accept"
            @click="triggerAction"
          >
            OK
          </button>
        </div>
        <!-- single button -->
        <div
          class="options-container singleButton"
          v-if="needsAcceptButton || needsCancelButton"
        >
          <button
            class="option-btn"
            data-action="cancel"
            v-if="needsCancelButton"
            @click="triggerAction"
          >
            CANCEL</button
          ><button
            class="option-btn"
            data-action="accept"
            v-if="needsAcceptButton"
            @click="triggerAction"
          >
            OK
          </button>
        </div>
        <!-- single button Map -->
        <div
          class="options-container singleButton"
          v-if="needsSelectButton"
        >
          <button
            class="option-btn locateMap"
            data-action="select"
            v-if="needsSelectButton"
            @click="selectAction"
          >
            {{ $t("map.btnLocate") }}
          </button>
        </div>
        <div
          class="options-container singleButton"
          v-if="needsGoMapButton"
        >
          <button
            class="option-btn locateMap"
            data-action="goMap"
            v-if="needsGoMapButton"
            @click="goMap"
          >
            {{ $t("goMap.button") }}
          </button>
        </div>

        <div
          class="options-container singleButton"
          v-if="needsGoRouteButton"
        >
          <button
            class="option-btn locateMap"
            data-action="goRoute"
            v-if="needsGoRouteButton"
            @click="goRoute"
          >
            {{ $t("goMap.button") }}
          </button>
        </div>
        <!-- close alert button -->
        <button
          class="close-modal"
          @click="dismissMessage"
          v-if="needsCloseButton || needsSelectButton || needsGoMapButton || needsGoRouteButton"
        >
          <img
            src="img/02_generales/cerrar@2x.png"
            alt="close sidebar button"
          />
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "AppAlert",
  data() {
    return {
      bgLayer: true,
      alertType: "message", // message, confirm, cancel, prompt
      shown: false,
      message: "",
      action: null,
    };
  },
  watch: {
    bgLayer: function (val) {
      if (val === "true") {
        document.querySelector(".bg-layer").classList.add("opaque");
      } else {
        document.querySelector(".bg-layer").classList.remove("opaque");
      }
    },
  },
  computed: {
    needsConfirmButtons: function () {
      return this.alertType === "prompt";
    },
    needsAcceptButton: function () {
      return this.alertType === "confirm";
    },
    needsCancelButton: function () {
      return this.alertType === "cancel";
    },
    needsCloseButton: function () {
      return this.alertType === "message";
    },
    needsSelectButton: function () {
      return this.alertType === "select";
    },
    needsGoMapButton: function () {
      return this.alertType === "goMap";
    },
    needsGoRouteButton: function () {
      return this.alertType === "goRoute";
    },
  },
  mounted() {},
  methods: {
    showAlert(options) {
      if (options.type != undefined) {
        this.alertType = options.type;
      }

      if (options.bgLayer != undefined) {
        this.bgLayer = options.bgLayer;
      }

      this.message = options.msg;
      this.action = options.promptAction;
      this.shown = true;
    },
    triggerAction(opt) {
      let option = opt.target.getAttribute("data-action");
      this.action(option);
      this.shown = false;
    },
    selectAction() {
      //let option = opt.target.getAttribute("data-action");
      //this.action(option);
      this.shown = false;
    },
    goMap() {
      this.$router.push({ name: "Map", query: { type: "spotsQR" } });
    },
    goRoute() {
      var current = this.$store.state.nextRoute.properties.endId;
      this.$store.commit("setRouteId", current);
      this.$router.push({ name: "Route"});
    },
    dismissMessage() {
      this.shown = false;
    },
  },
};
</script>

<style lang="scss">
.bg-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  z-index: 1000;

  &.opaque {
    background-color: var(--app-blackTransparency);
  }

  .alert-container {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: var(--app-modalBg);
    border: 0.2rem solid #aa4b39;
    border-radius: 0.5rem;
    width: 70%;
    padding: 0.5rem;
    font-family: "Lato";
    font-size: 1.2rem;

    .close-modal {
      position: absolute;
      width: 2.6rem;
      height: 2.6rem;
      top: -1rem;
      right: -1rem;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: transparent;
      z-index: 1;

      img {
        position: relative;
        width: 100%;
        height: 100%;
        -o-object-fit: contain;
        object-fit: contain;
      }
    }

    span {
      margin-bottom: 0.5rem;
    }

    &.onlyMessage {
      padding: 1rem;
      span {
        margin-bottom: 0;
      }
    }

    .options-container {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      padding: 0.5rem;
      width: 100%;

      .option-btn {
        position: relative;
        margin: 0rem;
        padding: 0.5rem 1rem;
        border: unset;
        width: auto;
        font-size: 0.8rem;
        background-color: var(--app-orange);
        color: var(--app-black);
        font-family: "Myriad-bold";
        border-radius: 0.4rem;
        text-transform: uppercase;
        text-align: center;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .locateMap {
        position: relative;
        margin: 0 auto;
        color: white;
        background: linear-gradient(0deg, #712112 0%, #aa4b39 40%, #c96c5a 80%, #e39421 100%);
        font-family: "Myriad-bold";
        border-radius: 3rem;
        //text-transform: uppercase;
        text-align: center;
        box-shadow: 0px 3px 4px 0px #707070;
        transition: all 0.2s ease;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        width: max-content;
        font-size: 1.3rem;
        padding: 0.5rem 0.7rem;
        margin-top: 0.5rem;
      }
    }
  }
}

.showUp-enter-active {
  transition: all 0.3s ease;
}
.showUp-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.showUp-enter,
.showUp-leave-to {
  transform: translateY(10px);
}
</style>
