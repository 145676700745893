"use strict";
class Route {
  constructor(data) {
    this.order = data.order;
    this.id = data.id;
    this.name = data.name;
    this.desc = data.desc;
    this.audio = data.audio;
    this.gallery = data.gallery;
    this.challenge = data.challenge;
    this.prize = data.prize;
  }
}

export default Route;
