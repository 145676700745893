<template>
  <div
    class="big-button"
    :class="[
      {
        answer: type === 'answer',
      },
      {
        noCaps: !allCaps,
      },
    ]"
    :data-selectable="selectable"
    @click="onClick"
  >
    <slot v-if="withImage == null || withImage == undefined"></slot>
    <img v-if="withImage != null" :src="getImage" alt="button image" />
  </div>
</template>

<script>
export default {
  name: "BigButton",
  props: {
    type: {
      type: String,
      required: false,
    },
    withImage: {
      type: String,
    },
    selectable: {
      type: Boolean,
      default: false,
      required: false,
    },
    onClick: {
      type: Function,
      required: true,
    },
    allCaps: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  mounted() {},
  computed: {
    getImage() {
      return require(`@/assets/button/${this.withImage}`);
    },
  },
};
</script>

<style lang="scss">
.big-button {
  position: relative;
  margin: 0.5rem 0rem;
  padding: 1rem;
  width: 99%;
  font-size: 1.6rem;
  //background-color: var(--app-orange);
  //color: var(--app-black);
  color: white;
  background: linear-gradient(0deg, #712112 0%, #aa4b39 40%, #c96c5a 80%, #e39421 100%);
  font-family: "Myriad-bold";
  border-radius: 3rem;
  text-transform: uppercase;
  text-align: center;
  box-shadow: 0px 3px 4px 0px darkgrey;
  transition: all 0.2s ease;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  &.noCaps {
    text-transform: none;
  }

  img {
    position: relative;
    width: auto;
    height: 65%;
  }

  &.answer {
    font-size: 1.4rem;
    padding: 0.8rem;
    //background-color: var(--app-black);
    background: linear-gradient(0deg, #4b1605 -50%, #e3313c 40%, #e25860 100%);
    color: var(--app-bg);
    box-shadow: 0px 3px 4px 0px darkgrey;
    margin: 0.5rem;
    //border: 1px solid #3e3e3e;
    font-family: "Lato";

    &.selected {
      background: #eaeaea;
      color: #5c5c5c;
      border: none;
      transform: translateY(0.2rem);
      box-shadow: 0px -3px 4px 0px darkgrey;
      //filter: saturate(2);
    }
  }

  &:active {
    transform: translateY(0.2rem);
    box-shadow: 0px 0px 4px 0px darkgrey;
    filter: saturate(2);
  }
}
</style>
